@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    .body-m {
        @apply text-16 leading-[1.255] l:text-20 lp:text-24;
    }

    .h1 {
        @apply text-58 leading-[1.1] mp:text-64 lp:text-96;
    }

    .h2 {
        @apply text-24 leading-[1.255] m:text-48 lp:text-64;
    }

    .h3 {
        @apply text-24 leading-[1.255] lp:text-36;
    }

    .label-m {
        @apply text-14 leading-[1.255] lp:text-18;
    }

    // btns
    .btn {
        @apply inline-block px-20 l:px-42 pt-11 pb-11 l:pt-15 l:pb-13 text-16 leading-20 rounded-full;

        transition-property: color, background, box-shadow;
        transition-duration: 0.3s;

        &:hover {
            box-shadow: none;
        }

        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }
        
        &--has-shadow {
            box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
        }

        &--light {
            @apply bg-white text-burgundy hover:bg-red hover:text-white
        }
        &--burgundy {
            @apply bg-burgundy text-white hover:bg-red hover:text-white
        }
    }

    .frontpage-hero-gradient {
        background: linear-gradient(165.32deg, #5D0036 27.63%, #2F001B 77.05%);;

        @screen m {
            background: #440825;
        }
    }

    .content-teaser-image-wrapper {
        padding-bottom: calc((550 / 500) * 100%);

        @screen m {
            padding-bottom: calc((730 / 950) * 100%);
        }
        @screen l {
            padding-bottom: calc((1000 / 1920) * 100%);
        }
        
        &.-has-overlay {
            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                
                @screen l {
                    background: linear-gradient(269.62deg, rgba(0, 0, 0, 0.6) 0.31%, rgba(0, 0, 0, 0.2) 99.67%);
                }
            }
        }
    }

    .content-teaser-image-bleed {
        width: calc(100% + 32px);
        transform: translateX(-16px);

        @screen m {
            width: calc(100% + 64px);
            transform: translateX(-32px);
        }
        @screen l {
            width: calc(100% + 40px);
            transform: translateX(0px);
        }
        @screen lp {
            width: calc(100% + 60px);
            transform: translateX(0px);
        }
    }

    .phone-case {
        filter: drop-shadow(-20px 30px 100px rgba(0, 0, 0, 0.2));

        &__video-holder {
            width: calc((352 / 395) * 100%);
            height: calc((662 / 800) * 100%);
            top: calc((118/800) * 100%);
            left: calc((20/395) * 100%);
            
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            
            @screen lp {
                border-bottom-left-radius: 40px;
                border-bottom-right-radius: 40px;
            }
        }
    }

    .progress-bullet-animation {
        transform: scale(1);
        animation: progresspulse 0.8s;
    }

    @keyframes progresspulse {
        0% {
            transform: scale(1);
        }
        30% {
            transform: scale(1.15);
        }
        60% {
            transform: scale(0.95);
        }
        80% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }
    
    .accordion-item {
        &__icon {
            span:last-child {
                transform-origin: center;
                transition: transform 0.2s ease-out;
                
                button[ aria-expanded="true"] & {
                    transform: scaleY(0);
                }
            }
        }
    }
    
    .default-form {
        .text-input {
            @apply bg-light-blue text-burgundy placeholder-burgundy font-medium px-40 py-20 w-full rounded-full;
            
            &.has-error {
                @apply bg-[#FFD0D6] text-red placeholder-red;
            }
        }
        
        .field-error-message {
            @apply text-14 text-red px-40 mt-5;
        }
        
        .error-message {
            @apply text-14 l:text-16 leading-1_2 bg-[#FFD0D6] text-red px-20 py-13;
        }
    }
}
