.page-header {
    @apply text-burgundy bg-white;
    transition-property: background, transform;
    transition-duration: 200ms, 500ms;
    transition-delay: 200ms, 0ms;
    transition-timing-function: ease, cubic-bezier(0.215, 0.610, 0.355, 1.000);

    path {
        @apply fill-burgundy;
        transition-property: fill;
        transition-duration: 200ms;
        transition-delay: 200ms;
        transition-timing-function: ease;
    }
    
    &__bg-gradient {
        @apply bg-gradient-to-b from-[rgba(0,0,0,0.5)] opacity-0;
        transition-property: opacity;
        transition-duration: 200ms;
        transition-delay: 200ms;
        transition-timing-function: ease;
        
        .is-transparent & {
            @apply opacity-100;
        }
    }

    &.is-transparent {
        @apply text-white bg-transparent;
        
        path {
            @apply fill-white;
        }
    }

    &.is-hidden:not(.is-locked) {
        transform: translate(0, -101%);
        transition-duration: 200ms, 400ms;
    }

    &.is-locked {
        transition-delay: 0ms, 0ms;
    }

    &.is-forced {
        transition-duration: 0ms !important;
        transition-delay: 0ms !important;
    }

}
