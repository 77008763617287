.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-1000 ease-out;
}

html.no-js {
    img.lazyload {
        display: none;
    }
}

html.outline,
html.no-outline * {
    outline: none !important;
}
