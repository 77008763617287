.wysiwyg {
    p {
        margin-bottom: calc((22/16) * 1em);

        &:last-child {
            margin-bottom: 0;
        }
    }
    
    i, em {
        font-style: italic;
    }
    
    strong, b {
        font-weight: normal;
    }
    
    a {
        text-decoration: underline;
    }
    
    &--simple {
        i, em, strong, b {
            @apply text-red;
            font-style: normal;
            font-weight: normal;
        }
    }
    
    h2 {
        text-transform: uppercase;
        margin-bottom: 1em;
    }
}
